html,
body,
#root {
  overflow-x: hidden;
}

.timeline-box {
  overflow: hidden;
  margin: 5rem;
}

.to-top-button {
  position: fixed;
  width: 100%;
  left: 45%;
  bottom: 10%;
  height: 20px;
  z-index: 99;
  cursor: pointer;
}

.underline {
  width: 100%;
  height: 8px;
  border-radius: 4px;
  position: absolute;
  bottom: -4px;
}

.skills-badges {
  justify-content: center;
}

@media only screen and (max-width: 900px) {
  .navigation-box {
    display: inline-block;
    height: 65px;
    width: 100%;
  }

  .skills-badges {
    overflow: auto;
    margin: 2rem;
    justify-content: left;
    padding: 2rem;
  }
}

@media only screen and (max-width: 734px) {
  .skills-badges {
    overflow: auto;
    margin: 2rem;
    justify-content: left;
    padding: 2rem;

  }
}

@media only screen and (max-width: 1200px) {
  .skills-badges {
    overflow: scroll;
    margin: 2rem;
    justify-content: left;
    padding: 2rem;

  }
}
