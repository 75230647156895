.map-header {
  /* border: 10px solid;
  border-color: red; */
  display: flex;
  padding: 1em;
  width: 100%;
  top: 0;
  /* left: 0; */
}

/*
* Main Page Styles
*/

.main-box {
  width: 100%;
  overflow: visible;
}

.intro-grid {
  transform: skewY(-50);
  position: relative;
  top: 5em;
  font-weight: bold;
  color: white;
  left: 0;
  width: 100%;
}

.image-grid {
  width: 40%;
  display: flex;
}

.image-icon {
  height: 300px;
  overflow: visible;
  z-index: 99;
  pointer-events: none;
}

.intro-box {
  width: 60%;
}

/*
* Drawing Page Styles
*/

.chat-box-div {
  /* border: solid 2px red; */
  bottom: 0;
  width: 100%;
}

.chat-box-display {
  background-color: rgb(231, 231, 231);
  position: relative;
  margin: 1em;
  height: 100%;
}

#chat-input {
  width: 80%;
  background-color: rgb(231, 231, 231);
}

/*
*
* About Me Page
*
*/

.timeline-box {
  overflow: visible;
  align-items: center;
}

.skills-animation:only-child {
  overflow: visible;
  background-color: blue;
}

/* 
---------------------------------------------------------------------------------------
*/

.blog-container {
  margin: 5rem;
}

/* contact
---------------------------------------------------------------------------------------
*/

.contact-box {
  /* border: solid 10px red; */
  overflow: hidden;
}


.contact-form {
  /* border: solid 10px red; */
  overflow: hidden;
}

.mailing-animation {
  overflow: visible;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
  pointer-events: none;
  bottom: 1rem;
  overflow-y: visible;
}

/* Mobile css
---------------------------------------------------------------------------------------
*/

@media only screen and (max-width: 900px) {
  .intro-grid {
    width: 50%;
    text-align: center;
    align-content: center;
    top: 1rem;
    margin-left: -1.5rem;
  }

  .image-grid {
    visibility: hidden;
    width: 10%;
    height: 1rem;
    overflow-x: hidden;
  }

  .timeline-box {
    /* center div contents */
    display: block;
    float: none;
    margin: 0;
    padding: 0;
    max-width: 50%;
  }

  .blog {
    /* center div contents */
    display: block;
    float: none;
    margin: 5%;
    overflow-x: hidden;
  }

}

@media only screen and (max-width: 734px) {
  .image-grid {
    visibility: hidden;
  }

  .intro-grid {
    width: 75%;
    text-align: center;
    align-content: center;
    top: 1rem;
  }

  .timeline-box {
    width: 90%;
    /* center div contents */
    display: inline;
    float: none;
  }

  .blog {
    width: 90%;
    /* center div contents */
    display: inline;
    float: none;
  }

  .main-project-box {
    width: 50%;
    /* center div contents */
    display: inline;
    float: none;
  }


}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 1200px) {
  .image-grid {
    visibility: hidden;
  }

  .intro-grid {
    width: 75%;
    text-align: center;
    align-content: center;
    top: 1rem;
  }

  .timeline-box {
    width: 90%;
    /* center div contents */
    display: inline;
    float: none;
  }

  .blog {
    width: 90%;
    /* center div contents */
    display: inline;
    float: none;
  }

  .main-project-box {
    width: 50%;
    /* center div contents */
    display: inline;
    float: none;
  }

}
