.App {
  text-align: center;
   scroll-behavior: smooth;
}


.navigation-bar {
  position: sticky;
  
}
